<template>
  <div>
    <!-- メンターのメッセージ動画 -->
    <div
      id="mentor-movie"
      class="mt-10 hidden"
    >
      <div
        class="mx-auto bg-white"
        style="max-width: 680px"
      >
        <div class="flex w-full flex-col items-center sm:flex-row">
          <div class="sm:w-1/3">
            <iframe
              class="te-mentor-movie aspect-video w-full"
              src="//www.youtube.com/embed/N8XTRdl5UDM?rel=0&amp;controls=0&amp;showinfo=0"
              frameborder="0"
              width="100%"
              allowfullscreen="true"
            />
          </div>
          <div class="p-5 sm:w-2/3 sm:py-6">
            <div class="co-display-10 mb-3 text-center font-bold leading-none sm:text-left">
              こんなメンターに相談できます！
            </div>
            <p class="mb-0">
              無料相談担当の1人、石津メンターからの一言メッセージ
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 予約に関する案内 -->
    <div class="mb-5 mt-12 flex justify-center">
      <div class="mr-4 flex items-center md:mx-6">
        <div class="te-firststep-icon mr-2 flex items-center justify-center rounded-full border-2 border-co-primary-500 bg-white">
          <i
            class="fa fa-lightbulb-o co-display-10 text-co-primary-500"
            aria-hidden="true"
          />
        </div>
        <div class="te-firststep-text text-left font-bold">
          当日1時間後から
          <br>
          予約OK
        </div>
      </div>
      <div class="mr-4 flex items-center md:mx-6">
        <div class="te-firststep-icon mr-2 flex items-center justify-center rounded-full border-2 border-co-primary-500 bg-white">
          <i
            class="fa fa-clock-o co-display-10 text-co-primary-500"
            aria-hidden="true"
          />
        </div>
        <div class="te-firststep-text text-left font-bold">
          8:00〜24:00まで
          <br>
          選択OK
        </div>
      </div>
    </div>
  <!-- //- TODO: 日程表を使うときに書き換えてください
  //- Calendar
  h3.co-display-10.text-center.font-weight-bold.co-mt-40.co-mb-28
    | ご希望の相談日時をお選びください
  .co-container-lg
    template(v-for='(appointmentDay, rowIndex) in appointmentDays')
      .d-lg-flex.justify-content-center(v-if='rowIndex == 0')
        h4.te-schedule-date.co-display-11.co-line-height-wide.font-weight-bold.text-center.text-lg-left.co-mb-16
          | {{ dateToStr(appointmentDay) }}
        CoScrollableContainer(label='横スクロールできます')
          .d-flex.co-pb-12.co-pb-lg-0
            .co-mr-20
              .te-schedule-apply-hour.co-mb-4.d-flex(
                v-for='hour in [8, 9, 10, 11, 12, 13]'
              )
                template(v-for='appintmentTime in appintmentTimes(hour)')
                  b-button.te-schedule-apply-button.co-bg-white-1.co-bg-hover-gray-6.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-b-modal.te-mentoring-modal,
                    @click='sendAppointmentDate(appintmentTime.time, rowIndex)',
                    v-if='appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
                  .te-no-select.te-schedule-apply-button.btn.btn-secondary.co-bg-white-1.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-if='!appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
            .co-mr-20
              .te-schedule-apply-hour.co-mb-4.d-flex(
                v-for='hour in [14, 15, 16, 17, 18, 19]'
              )
                template(v-for='appintmentTime in appintmentTimes(hour)')
                  b-button.te-schedule-apply-button.co-bg-white-1.co-bg-hover-gray-6.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-b-modal.te-mentoring-modal,
                    @click='sendAppointmentDate(appintmentTime.time, rowIndex)',
                    v-if='appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
                  .te-no-select.te-schedule-apply-button.btn.btn-secondary.co-bg-white-1.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-if='!appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
            .co-mr-0
              .te-schedule-apply-hour.co-mb-4.d-flex(
                v-for='hour in [20, 21, 22, 23]'
              )
                template(v-for='appintmentTime in appintmentTimes(hour)')
                  b-button.te-schedule-apply-button.co-bg-white-1.co-bg-hover-gray-6.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-b-modal.te-mentoring-modal,
                    @click='sendAppointmentDate(appintmentTime.time, rowIndex)',
                    v-if='appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
                  .te-no-select.te-schedule-apply-button.btn.btn-secondary.co-bg-white-1.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-if='!appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
      .d-lg-flex.justify-content-center.co-mt-48(v-if='rowIndex == 1')
        h4.te-schedule-date.co-display-11.co-line-height-wide.font-weight-bold.text-center.text-lg-left.co-mb-16
          | {{ dateToStr(appointmentDay) }}
        CoScrollableContainer(label='横スクロールできます')
          .d-flex.co-pb-12.co-pb-lg-0
            .co-mr-20
              .te-schedule-apply-hour.co-mb-4.d-flex(
                v-for='hour in [8, 9, 10, 11, 12, 13]'
              )
                template(v-for='appintmentTime in appintmentTimes(hour)')
                  b-button.te-schedule-apply-button.co-bg-white-1.co-bg-hover-gray-6.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-b-modal.te-mentoring-modal,
                    @click='sendAppointmentDate(appintmentTime.time, rowIndex)',
                    v-if='appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
                  .te-no-select.te-schedule-apply-button.btn.btn-secondary.co-bg-white-1.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-if='!appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
            .co-mr-20
              .te-schedule-apply-hour.co-mb-4.d-flex(
                v-for='hour in [14, 15, 16, 17, 18, 19]'
              )
                template(v-for='appintmentTime in appintmentTimes(hour)')
                  b-button.te-schedule-apply-button.co-bg-white-1.co-bg-hover-gray-6.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-b-modal.te-mentoring-modal,
                    @click='sendAppointmentDate(appintmentTime.time, rowIndex)',
                    v-if='appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
                  .te-no-select.te-schedule-apply-button.btn.btn-secondary.co-bg-white-1.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-if='!appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
            .co-mr-0
              .te-schedule-apply-hour.co-mb-4.d-flex(
                v-for='hour in [20, 21, 22, 23]'
              )
                template(v-for='appintmentTime in appintmentTimes(hour)')
                  b-button.te-schedule-apply-button.co-bg-white-1.co-bg-hover-gray-6.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-b-modal.te-mentoring-modal,
                    @click='sendAppointmentDate(appintmentTime.time, rowIndex)',
                    v-if='appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }}
                  .te-no-select.te-schedule-apply-button.btn.btn-secondary.co-bg-white-1.co-text-gray-1.co-border-gray-4.co-p-8(
                    v-if='!appointmentTable[rowIndex][appintmentTime.num]'
                  )
                    | {{ appintmentTime.time }} -->
  <!-- //- TODO: モーダルを使うときに書き換えてください
  //- Modal
  TeCommonCounselingScheduleApplyModal(
    :appointmentForm='appointmentForm',
    :appointmentDate='appointmentDate'
  ) -->
  </div>

<!-- div
  //- メンターのメッセージ動画
  #mentor-movie.d-none.co-mt-40
    .mx-auto.co-bg-white-1(style='max-width: 680px')
      .row.no-gutters.w-100.d-flex.align-items-center
        .col-sm-4
          iframe.te-mentor-movie.embed-responsive-item.w-100(
            src='//www.youtube.com/embed/N8XTRdl5UDM?rel=0&amp;controls=0&amp;showinfo=0',
            frameborder='0',
            width='100%',
            allowfullscreen='true'
          )
        .col-sm-8.co-p-20.co-py-sm-24
          .text-center.text-md-left.font-weight-bold.co-display-10.co-line-height-1.co-mb-12
            | こんなメンターに相談できます！
          p.co-mb-0
            | 無料相談担当の1人、石津メンターからの一言メッセージ
  //- 予約に関する案内
  .d-flex.justify-content-center.co-mb-20.co-mt-48
    .d-flex.align-items-center.co-mr-16.co-mx-md-24
      .te-firststep-icon.d-flex.align-items-center.justify-content-center.co-mr-8.border.co-border-width-2.co-border-primary-1.co-bg-white-1.rounded-circle
        i.fa.fa-lightbulb-o.co-text-primary-1.co-display-10(aria-hidden='true')
      .font-weight-bold.te-firststep-text.text-left
        | 当日1時間後
        span.font-size-small から
        br
        | 予約OK
    .d-flex.align-items-center.co-mr-16.co-mx-md-24
      .te-firststep-icon.d-flex.align-items-center.justify-content-center.co-mr-8.border.co-border-width-2.co-border-primary-1.co-bg-white-1.rounded-circle
        i.fa.fa-clock-o.co-text-primary-1.co-display-10(aria-hidden='true')
      .font-weight-bold.te-firststep-text.text-left
        | 8:00〜24:00
        span.font-size-small まで
        br
        | 選択OK -->
</template>

<script lang="ts">
import { daysOfWeek } from '~/utility/utility'

interface Data {
  appointmentDate: Date | null
}

interface Methods {
  create2daysArray: (date: Date | null) => Date[] | null[]
  sendAppointmentDate: (time: string, index: number) => void
  dateToStr: (date: Date) => string
}

interface Computed {
  table: boolean[][]
  appointmentDays: Date[] | null[]
  appintmentTimes: (hour: number) => any
  appointmentTable: boolean[][]
}

interface Props {
  appointmentForm: any
  appointmentFormTable: any
}

const options = {
  props: {
    appointmentForm: {
      default: null,
    },
    appointmentFormTable: {
      default: null,
    },
  },
  data() {
    return {
      appointmentDate: null,
    }
  },
  mounted() {},
  computed: {
    table() {
      if (!this.appointmentFormTable) return null
      return this.appointmentFormTable.table
    },
    appointmentDays() {
      if (!this.appointmentForm) return this.create2daysArray(null)
      const submittedBeginsAtDate = new Date(
        this.appointmentForm.submitted_begins_at
      )
      return this.create2daysArray(submittedBeginsAtDate)
    },
    appintmentTimes() {
      return function (hour) {
        const rangeFrom0To31 = Array.from(Array(32), (_, k) => k)
        const times = rangeFrom0To31.map((num, i) => {
          if (i % 2 === 0) {
            const n = 8 + num / 2
            if (n === hour) {
              return {
                num,
                time: `${n}:00`,
              }
            } else {
              return {}
            }
          } else {
            const n = Math.floor(8 + (num - 1) / 2)
            if (n === hour) {
              return {
                num,
                time: `${n}:30`,
              }
            } else {
              return {}
            }
          }
        })
        return times.filter((obj) => Object.keys(obj).length)
      }
    },
    appointmentTable() {
      if (!this.table) {
        const initialValue = [...Array(32)].map(() => {
          return [...Array(2)].map(() => {
            return false
          })
        })
        return initialValue
      } else {
        return this.table
      }
    },
  },
  methods: {
    create2daysArray(date: Date | null): Date[] | null[] {
      if (!date) return [...Array(2)].map(() => null)
      const today = new Date(date) as Date
      if (today.getHours() >= 22) {
        today.setDate(today.getDate() + 1)
      }
      const days = [...Array(2)].map((_, index) => {
        const day = new Date()
        day.setDate(today.getDate() + index)
        day.setHours(0)
        day.setMinutes(0)
        day.setSeconds(0)
        return day
      })
      return days
    },
    sendAppointmentDate(time, index) {
      const day = this.appointmentDays[index]
      if (!day) return null
      const [hour, minute] = time.split(':')
      const date = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        Number(hour),
        Number(minute)
      )
      this.appointmentDate = date
    },
    dateToStr(date) {
      if (date == null) return '-/- (-)'
      return `${date.getMonth() + 1}/${date.getDate()} (${daysOfWeek(date)})`
    },
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-firststep-icon {
  min-width: 2.25rem;
  max-width: 2.25rem;
  min-height: 2.25rem;
  max-height: 2.25rem;
}

.te-firststep-text {
  line-height: 1.5;
  color: #1f7f96;
}

/* 申し込み 日程表 */
.te-schedule-date {
  @media (min-width: 992px) {
    width: 7.5rem;
  }
}

.te-schedule-apply-hour {
  .te-schedule-apply-button {
    width: 8rem;
    &:first-of-type {
      margin-right: 0.25rem;
    }
  }
  .te-no-select {
    font-size: 0;
    cursor: auto;
    opacity: 0.3;
    &:active {
      color: #333;
    }
    &::before {
      font-size: 1rem;
      content: '空席なし';
    }
  }
}

/* メンターのメッセージ動画 */
.te-mentor-movie {
  height: 12rem;
  vertical-align: bottom;
  @media (min-width: 576px) {
    height: 8.5rem;
  }
}
</style>
